import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Admin.css";
import AdminSidebar from "../components/AdminSidebar";
import AdminHeader from "../components/AdminHeader";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import openNotification from "../../components/OpenNotification";
import { DatePicker, Select } from "antd";

import Chart from "react-apexcharts";

const { Option } = Select;

const TransactionAnalytics = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const currentYear = new Date().getFullYear();
  const today = dayjs();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [titleYear, setTitleYear] = useState(currentYear);
  // const minStartDate= useState(dayjs(`01-01-${selectedYear}`));
  const [currencies, setCurrencies] = useState([]);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [transactionType, setTransactionType] = useState();
  const [currency, setCurrency] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [transactionVolumeData, setTransactionVolumeData] = useState({
    series: [
      {
        name: "Buy",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Sell",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Send",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Receive",
        data: Array.from({ length: 12 }, () => 0),
      },
    ],
    options: {
      chart: { id: "transaction-volume" },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      stroke: { curve: "smooth" },
      markers: { size: 5 },
      dataLabels: { enabled: false },
      legend: { position: "top" },
      // title: { text: `Transaction Volume - ${selectedYear}`, align: "center" },
    },
  });

  const [transactionDistributionData, setTransactionDistributionData] = useState({
    series: [], 
    options: {
      chart: { id: "transaction-distribution", type: "pie" },
      legend: { position: "bottom", color: "#fff" },
      labels: [],
      dataLabels: { enabled: true },
    },
  });


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    document.title = "Transaction Analytics | BarterFunds";
    const token = window.sessionStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }
    
    setIsLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/currencies`, { headers: headers })
      .then((response) => {
        // console.log(response)
        if (response.data.success) {
          setCurrencies(response.data.currencies);
          // setIsLoading(false);
        } else {
          setCurrencies([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/analytics/transactions`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);

          setTransactionVolumeData((prev) => ({
            ...prev,
            series: [
              {
                name: "Buy",
                data: response.data.monthlyVolume.buy,
              },
              {
                name: "Sell",
                data: response.data.monthlyVolume.sell,
              },
              {
                name: "Send",
                data: response.data.monthlyVolume.send,
              },
              {
                name: "Receive",
                data: response.data.monthlyVolume.receive,
              },
            ],
          }));

          const transactionTypeDistribution =
            response.data.transactionTypeDistribution;

            setTransactionDistributionData((prev) => ({
              ...prev,
              series: Object.values(transactionTypeDistribution), 
              options: {
                ...prev.options,
                labels: Object.keys(transactionTypeDistribution).map(
                  (category) => capitalizeFirstLetter(category)), 
              }
            }));
    


          setIsLoading(false);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  const handleFilter = () => {
    const token = window.sessionStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (startDate && !endDate) {
      openNotification(
        "topRight",
        "error",
        "End Date",
        "Please select an end date."
      );
      // alert("Please select an end date.");
      return;
    }

    setIsLoading(true);

    // Build the URL dynamically
    const baseUrl = `${process.env.REACT_APP_API_URL}/analytics/transactions`;
    const params = new URLSearchParams();

    params.append("year", selectedYear);

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    if (transactionType) {
      params.append("transactionType", transactionType);
    }
    if (currency) {
      params.append("currency", currency);
    }
    if (paymentMethod) {
      params.append("paymentMethod", paymentMethod);
    }

    const finalUrl = `${baseUrl}?${params.toString()}`;
    // console.log("Request URL:", finalUrl);

    axios
      .get(finalUrl, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);
          setTitleYear(response?.data?.year);

          setTransactionVolumeData((prev) => ({
            ...prev,
            series: [
              {
                name: "Buy",
                data: response.data.monthlyVolume.buy,
              },
              {
                name: "Sell",
                data: response.data.monthlyVolume.sell,
              },
              {
                name: "Send",
                data: response.data.monthlyVolume.send,
              },
              {
                name: "Receive",
                data: response.data.monthlyVolume.receive,
              },
            ],
          }));

          const transactionTypeDistribution =
            response.data.transactionTypeDistribution;

            setTransactionDistributionData((prev) => ({
              ...prev,
              series: Object.values(transactionTypeDistribution), 
              options: {
                ...prev.options,
                labels: Object.keys(transactionTypeDistribution).map(
                  (category) => capitalizeFirstLetter(category)), 
              }
            }));

          setIsLoading(false);
        } else {
          // setData([]);
          openNotification("topRight", "error", "Error", "Error fetching data");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatCurrency = (value) => {
    const number = Number(value);

    if (!Number.isFinite(number)) {
      return "Invalid number";
    }

    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="page-wrapper default-version">
      <AdminSidebar active={"users-analytics"} />
      <AdminHeader />

      {isLoading && <Loader />}
      <div className="body-wrapper bg-gray-100 px-3">
        <div className="bodywrapper__inner">
          {/* Filters Section */}
          <div className="container px-0 d-flex w-100 flex-column gy-3 g-lg-0 justify-content-between align-items-center mb-4">
            <div className="w-100 mb-3 mb-lg-0 ">
              <h4 className="fw-bold">
                Transaction Analytics for {titleYear}{" "}
              </h4>
            </div>

            <div className="w-100 overflow-auto overflow-lg-visible py-3 ">
              <div className="d-flex flex-nowrap gap-3">
                <div className="d-flex flex-row flex-nowrap gap-1 flex-shrink-0">
                  <p className="text-black mt-1">From:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="startDate"
                    size={"small"}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="d-flex flex-row gap-1  flex-shrink-0">
                  <p className="text-black mt-1">To:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="endDate"
                    disabled={!startDate}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={today}
                  />
                </div>

                <Select
                  value={selectedYear}
                  style={{ width: 120 }}
                  placeholder="Select a year"
                  onChange={(value) => {
                    setSelectedYear(value);
                  }}
                >
                  <Option value="">Select a year</Option>
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                </Select>
                <Select
                  defaultValue={transactionType}
                  style={{ width: 120, color: "black" }}
                  placeholder="Transaction type"
                  onChange={(value) => {
                    setTransactionType(value);
                  }}
                  className="text-black"
                >
                  <Option selected>Transaction type</Option>
                  <Option value="buy">Buy</Option>
                  <Option value="sell">Sell</Option>
                  <Option value="send">Send</Option>
                  <Option value="receive">Receive</Option>
                </Select>
                <Select
                  defaultValue={currency}
                  showSearch
                  style={{ width: 120 }}
                  placeholder="Select digital asset"
                  onChange={(value) => {
                    setCurrency(value);
                  }}
                >
                  <Option value="" selected>
                    Select digital asset
                  </Option>
                  {currencies.map((currency) => {
                    return (
                      <Option value={currency._id}>
                        {currency.currencyName}
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  value={paymentMethod}
                  style={{ width: 120 }}
                  placeholder="Select payment method"
                  onChange={(value) => {
                    setPaymentMethod(value);
                  }}
                >
                  <Option value="" selected>
                    Select a payment method
                  </Option>
                  <Option value="momo">Mobile Money</Option>
                  <Option value="bank">Bank</Option>
                  <Option value="wallet">Wallet</Option>
                </Select>

                <button
                  className="btn btn-primary p-3 flex-shrink-0"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>

          <hr />

          {/* Cards */}
          <div className="row gy-4 mt-3 mt-lg-0">
            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-users f-size--56 f-size--56 text-black" />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Total Transactions
                      </span>
                      <h3 className="text-black">
                        {formatCurrency(data?.totalTransactionVolume || 0) ||
                          0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2 text-black">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-user-check f-size--56 f-size--56 " />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Avg. Daily Transactions
                      </span>
                      <h3 className="text-black">
                        {formatCurrency(data?.avgDailyTransaction || 0) || 0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-user-times f-size--56 f-size--56 text-black " />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Successful Transactions
                      </span>
                      <h3 className="text-black ">
                        {data?.successfulTransactions || 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="las la-user-clock f-size--56 f-size--56 text-black" />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Failed Transactions
                      </span>
                      <h3 className="text-black">
                        {data?.failedTransactions || 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          {/* Line Chart */}
          <div className=" py-4">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3">
                    Monthly Transaction Volume - {titleYear}{" "}
                  </h5>
                  <Chart
                    options={transactionVolumeData.options}
                    series={transactionVolumeData.series}
                    type="line"
                    height={300}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3">
                    Transaction Types Distribution - {titleYear}{" "}
                  </h5>
                  <Chart
                    options={transactionDistributionData.options}
                    series={transactionDistributionData.series}
                    type="pie"
                    height={500}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionAnalytics;
