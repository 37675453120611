import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Admin.css";
import AdminSidebar from "../components/AdminSidebar";
import AdminHeader from "../components/AdminHeader";
import openNotification from "../../components/OpenNotification";

import axios from "axios";
import Loader from "../../components/Loader";
import dayjs from "dayjs";

import { DatePicker, Select } from "antd";

const { Option } = Select;

const ReferralAnalytics = () => {
  const isSmallScreen = window.innerWidth <= 768;

  const navigate = useNavigate();
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const currentYear = new Date().getFullYear();
  const today = dayjs();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const minStartDate = useState(dayjs(`01-01-${selectedYear}`));
  const [titleYear, setTitleYear] = useState(currentYear);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [referralsList, setReferralsList] = useState([]);

  useEffect(() => {
    document.title = "Referral Analytics | BarterFunds";
    const token = window.sessionStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }
    
    setIsLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/analytics/referrals`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);
          setReferralsList(response.data.latestReferrals);

          setIsLoading(false);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  const handleFilter = () => {
    const token = window.sessionStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (startDate && !endDate) {
      openNotification(
        "topRight",
        "error",
        "End Date",
        "Please select an end date."
      );
      // alert("Please select an end date.");
      return;
    }

    setIsLoading(true);

    // Build the URL dynamically
    const baseUrl = `${process.env.REACT_APP_API_URL}/analytics/referrals`;
    const params = new URLSearchParams();

    params.append("year", selectedYear);

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    const finalUrl = `${baseUrl}?${params.toString()}`;
    // console.log("Request URL:", finalUrl);

    axios
      .get(finalUrl, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          setData(response.data);
          setTitleYear(response?.data?.year);
          setReferralsList(response.data.latestReferrals);
          setIsLoading(false);
        } else {
          // setData([]);
          openNotification("topRight", "error", "Error", "Error fetching data");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPM = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    hours = hours.toString().padStart(2, "0");

    return `${hours}:${minutes} ${amPM}`;
  };

  const formatCurrency = (value) => {
    const number = Number(value);

    if (!Number.isFinite(number)) {
      return "Invalid number";
    }

    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="page-wrapper default-version">
      <AdminSidebar active={"users-analytics"} />
      <AdminHeader />

      {isLoading && <Loader />}
      <div className="body-wrapper bg-gray-100 px-3">
        <div className="bodywrapper__inner">
          {/* Filters Section */}
          <div className="container px-0 d-flex w-100 flex-column gy-3 g-lg-0 justify-content-between align-items-center mb-4">
            <div className="w-100 mb-3 mb-lg-0 ">
              <h4 className="fw-bold">Referral Analytics for {titleYear}</h4>
            </div>

            <div className="w-100 overflow-auto overflow-lg-visible py-3 ">
              <div className="d-flex flex-nowrap gap-3">
                <div className="d-flex flex-row flex-nowrap gap-1 flex-shrink-0">
                  <p className="text-black mt-1">From:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="startDate"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="d-flex flex-row gap-1  flex-shrink-0">
                  <p className="text-black mt-1">To:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="endDate"
                    disabled={!startDate}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={today}
                  />
                </div>

                <Select
                  // defaultValue={selectedYear}
                  style={{ width: 120 }}
                  placeholder="Select a year"
                  onChange={(value) => {
                    setSelectedYear(value);
                  }}
                >
                  <Option value="" selected>
                    Select a year
                  </Option>
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                </Select>

                <button
                  className="btn btn-primary p-3 flex-shrink-0"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
          <hr />

          {/* Cards */}

          <div className="d-flex flex-wrap gap-3 mb-5">
            <div className="flex-fill text-center">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <h2 className="text-black">{data?.totalReferrals || 0}</h2>
                  <span className="text-black text--small">
                    Total Referrals
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-fill text-center">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <h2 className="text-black">{data?.activeReferrals || 0}</h2>
                  <span className="text-black text--small">
                    Active Referrals
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-fill text-center">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <h2 className="text-black">
                    {formatCurrency(data?.totalCommissionEarned || 0) || 0.0}
                  </h2>
                  <span className="text-black text--small">
                    Commision Earned
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="flex-fill text-center">
                <div className="card bg-white overflow-hidden box--shadow2">
                  <div className="card-body">
                    <h2 className="text-black">{formatCurrency(data?.withdrawnCommission || 0) ||
                          0.0}</h2>
                    <span className="text-black text--small">
                      Withdrawn Commission
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex-fill text-center">
                <div className="card bg-white overflow-hidden box--shadow2">
                  <div className="card-body">
                    <h2 className="text-black">{formatCurrency(data?.pendingCommission || 0) ||
                          0.0}</h2>
                    <span className="text-black text--small">
                      Pending Commission
                    </span>
                  </div>
                </div>
              </div> */}
          </div>

          <br />
          <br />
          <br />

          <div className="mt-5 mt-lg-0">
            <div className="d-flex justify-content-between align-items-center">
              <h6
                className="page-title"
                style={{ display: isSmallScreen ? "none" : "block" }}
              >
                Recent Referrals{" "}
              </h6>

              <div className="d-flex flex-wrap justify-content-end align-items-center breadcrumb-plugins"></div>

              {/* <div
                  className="d-flex flex-wrap justify-content-end align-items-center breadcrumb-plugins"
                  style={{ marginLeft: isSmallScreen ? "10px" : "" }}
                >
                  <div className="input-group w-auto flex-fill">
                    <input
                      type="search"
                      name="search"
                      className="form-control bg--white text-white"
                      placeholder="Username / Email / Contact"
                    />
                    <button className="btn btn--primary" type="submit">
                      <i className="la la-search" />
                    </button>
                  </div>
                </div> */}
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card b-radius--10 ">
                  <div className="card-body p-0">
                    <div className="table-responsive--md  table-responsive">
                      <table className="table table--light">
                        <thead>
                          <tr>
                            <th>Referral ID</th>
                            <th>Referrer</th>
                            <th>Refered User</th>
                            <th>Referal code</th>
                            <th>Date Joined</th>
                            {/* <th>Commission Earned</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {referralsList?.length === 0 ? (
                            <tr>
                              <td colSpan="8">No data</td>{" "}
                            </tr>
                          ) : (
                            referralsList.map((referral, index) => (
                              <tr>
                                <td>{`#RF-10${index}`}</td>

                                <td>
                                  <span className="d-block">
                                    {referral?.referrer?.firstname}{" "}
                                    {referral?.referrer?.surname}
                                  </span>
                                  <span>
                                    <a
                                      className="text--primary"
                                      href={`/admin/users/details/${referral?.referrer?._id}`}
                                    >
                                      <span className="text--primary">@</span>
                                      {referral?.referrer?.username}
                                    </a>
                                  </span>
                                </td>
                                <td>
                                  <span className="d-block">
                                    {referral?.referee?.firstname}{" "}
                                    {referral?.referee?.surname}
                                  </span>
                                  <span>
                                    <a
                                      className="text--primary"
                                      href={`/admin/users/details/${referral?.referee?._id}`}
                                    >
                                      <span className="text--primary">@</span>
                                      {referral?.referee?.username}
                                    </a>
                                  </span>
                                </td>
                                <td className="text-center">
                                  {referral?.referralCode}
                                </td>
                                <td>
                                  <span className="d-block">
                                    {formatDate(referral?.createdAt)}
                                  </span>
                                  <span>{formatTime(referral?.createdAt)}</span>
                                </td>

                                {/* <td>
                            <span className="d-block">GHS {formatCurrency(referral?.refereeCommissionEarned)}</span>
                          </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralAnalytics;
