import React, { useState } from "react";

const AdminSidebar = ({ active }) => {
  const [icon, setIcon] = useState("bars");
  const [background, setBackground] = useState("transparent");
  const isSmallScreen = window.innerWidth <= 768;
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar");

    const currentLeft = sidebar.style.left;

    if (currentLeft === "0px" || currentLeft === "") {
      sidebar.style.left = "-250px"; // Hide the sidebar
      setIcon("bars");
      setBackground("transparent");
    } else {
      sidebar.style.left = "0px"; // Show the sidebar
      setIcon("times");
      setBackground("[#810020]");
    }
  };

  return (
    <>
      <div
        className="sidebar open bg--dark"
        style={{
          left: isSmallScreen ? "-250px" : "0",
          position: "fixed",
          transition: "left 0.3s ease",
        }}
      >
        <button
          className={`res-sidebar-close-btn mt-3 bg-${background}`}
          onClick={toggleSidebar}
        >
          <i className={`las la-${icon}`} />
        </button>

        <div className="sidebar__inner">
          <div className="sidebar__logo">
            <a href={`/admin/dashboard`} className="sidebar__main-logo">
              <img src="/assets/images/logo-dark.png" alt="sidebar logo" />
            </a>
          </div>

          <div
            className="sidebar__menu-wrapper overflow-auto"
            id="sidebar__menuWrapper"
            style={{
              maxHeight: "calc(100vh - 100px)",
              overflowY: "auto",
            }}
          >
            <ul className="sidebar__menu">
              <li
                className={`sidebar-menu-item ${
                  active === "dashboard" ? "active" : ""
                }`}
              >
                <a href={`/admin/dashboard`} className="nav-link">
                  <i className="menu-icon las la-home" />
                  <span className="menu-title">Dashboard</span>
                </a>
              </li>

              {/* Analytics Dropdown */}
              <li
                className={`sidebar-menu-item ${
                  active === "user-analytics" ? "active" : ""
                }`}
              >
                <button
                  className="nav-link d-flex align-items-center w-100"
                  onClick={() => setIsAnalyticsOpen(!isAnalyticsOpen)}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#fff",
                    textAlign: "left",
                  }}
                >
                  <i className="menu-icon las la-chart-pie" />
                  <span className="menu-title">Analytics</span>
                  <i
                    className={`float-right las la-caret-${
                      isAnalyticsOpen ? "down" : "right"
                    } caret-icon`}
                    style={{
                      fontSize: "1.2rem",
                      marginLeft: "auto",
                    }}
                  />
                </button>

                <ul
                  className={`sidebar-submenu ${isAnalyticsOpen ? "show" : ""}`}
                >
                  <li
                    className={`sidebar-submenu-item ${
                      active === "user-analytics" ? "active" : ""
                    }`}
                  >
                    <a href={`/admin/analytics/users`}>
                      {/* <a href={`/`}> */}
                      <i className="las la-user" /> <span>User Analytics</span>
                    </a>
                  </li>
                  <li
                    className={`sidebar-submenu-item  ${
                      active === "transaction-analytics" ? "active" : ""
                    }`}
                  >
                    <a href={`/admin/analytics/transactions`}>
                      {/* <a href={`/`}> */}
                      <i className="las la-chart-line" />{" "}
                      <span>Transaction Analytics</span>
                    </a>
                  </li>
                  <li
                    className={`sidebar-submenu-item  ${
                      active === "revenue-analytics" ? "active" : ""
                    }`}
                  >
                    <a href={`/admin/analytics/revenue`}>
                      <i className="las la-wallet" />
                      <span>Revenue & Fees</span>
                    </a>
                  </li>
                  <li
                    className={`sidebar-submenu-item  ${
                      active === "revenue-analytics" ? "active" : ""
                    }`}
                  >
                    <a href={`/admin/analytics/orders`}>
                      <i className="las la-money-bill" />
                      <span>Order Analytics</span>
                    </a>
                  </li>
                  <li
                    className={`sidebar-submenu-item  ${
                      active === "revenue-analytics" ? "active" : ""
                    }`}
                  >
                    <a href={`/admin/analytics/referrals`}>
                      <i className="las la-file-invoice-dollar" />
                      <span>Referral Analytics</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "users" ? "active" : ""
                }`}
              >
                <a href={`/admin/users`} className="">
                  <i className="menu-icon las la-users" />
                  <span className="menu-title">Users</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "currency" ? "active" : ""
                }`}
              >
                <a href={`/admin/currencies`} className="nav-link">
                  <i className="menu-icon las la-money-bill" />
                  <span className="menu-title">Currencies</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "kyc" ? "active" : ""
                }`}
              >
                <a href={`/admin/kycs`} className="">
                  <i className="menu-icon las la-address-card"></i>
                  <span className="menu-title">KYCs</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "transaction" ? "active" : ""
                }`}
              >
                <a href={`/admin/transactions`} className="">
                  <i className="menu-icon las la-exchange-alt" />
                  <span className="menu-title">Transactions</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "order" ? "active" : ""
                }`}
              >
                <a href={`/admin/orders`} className="">
                  <i className="menu-icon la la-bank" />
                  <span className="menu-title">Orders</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "tickets" ? "active" : ""
                }`}
              >
                <a href={`/admin/tickets`} className="">
                  <i className="menu-icon la la-ticket" />
                  <span className="menu-title">Support Tickets</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "referral" ? "active" : ""
                }`}
              >
                <a href={`/admin/referrals`} className="">
                  <i className="menu-icon las la-comments-dollar" />
                  <span className="menu-title">Referrals</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "wallet" ? "active" : ""
                }`}
              >
                <a href={`/admin/wallets`} className="">
                  <i className="menu-icon las la-wallet" />
                  <span className="menu-title">Wallets</span>
                </a>
              </li>

              <li
                className={`sidebar-menu-item ${
                  active === "report" ? "active" : ""
                }`}
              >
                <a href={`/admin/reports`} className="">
                  <i className="menu-icon la la-list" />
                  <span className="menu-title">Activity Log</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
