import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Admin.css";
import AdminSidebar from "../components/AdminSidebar";
import AdminHeader from "../components/AdminHeader";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import openNotification from "../../components/OpenNotification";
import { DatePicker, Select } from "antd";

import Chart from "react-apexcharts";

const { Option } = Select;

const OrderAnalytics = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const currentYear = new Date().getFullYear();
  const today = dayjs();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [titleYear, setTitleYear] = useState(currentYear);
  // const minStartDate= useState(dayjs(`01-01-${selectedYear}`));
  // const [currencies, setCurrencies] = useState([]);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [action, setAction] = useState();

  const [ordersData, setOrdersData] = useState({
    series: [
      {
        name: "Deposit",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Withdrawal",
        data: Array.from({ length: 12 }, () => 0),
      },
    ],
    options: {
      chart: { id: "orders-volume" },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      stroke: { curve: "smooth" },
      markers: { size: 5 },
      dataLabels: { enabled: false },
      legend: { position: "top" },
    },
  });

  const [transactionData, setTransactionData] = useState({
    series: [
      {
        name: "Success",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Failed",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Cancelled",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "Pending",
        data: Array.from({ length: 12 }, () => 0),
      },
    ],
    options: {
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      stroke: { curve: "smooth" },
      markers: { size: 5 },
      dataLabels: { enabled: false },
      legend: { position: "top" },
    },
  });

  useEffect(() => {
    document.title = "Order Analytics | BarterFunds";
    const token = window.sessionStorage.getItem("token");

    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/analytics/orders`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);
          setTitleYear(response?.data?.year);

          setOrdersData((prev) => ({
            ...prev,
            series: [
              {
                name: "Deposit",
                data: response.data.ordersData.deposit,
              },
              {
                name: "Withdrawal",
                data: response.data.ordersData.withdraw,
              },
            ],
          }));

          setTransactionData((prev) => ({
            ...prev,
            series: [
              {
                name: "Success",
                data: response.data.transactionData.success,
              },
              {
                name: "Failed",
                data: response.data.transactionData.failed,
              },
              {
                name: "Cancelled",
                data: response.data.transactionData.cancelled,
              },
              {
                name: "Pending",
                data: response.data.transactionData.pending,
              },
            ],
          }));

          setIsLoading(false);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
  }, [navigate]);

  const handleFilter = () => {
    const token = window.sessionStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (startDate && !endDate) {
      openNotification(
        "topRight",
        "error",
        "End Date",
        "Please select an end date."
      );
      // alert("Please select an end date.");
      return;
    }

    setIsLoading(true);

    // Build the URL dynamically
    const baseUrl = `${process.env.REACT_APP_API_URL}/analytics/orders`;
    const params = new URLSearchParams();

    params.append("year", selectedYear);

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    if (action) {
      params.append("action", action);
    }

    const finalUrl = `${baseUrl}?${params.toString()}`;
    // console.log("Request URL:", finalUrl);

    axios
      .get(finalUrl, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);
          setTitleYear(response?.data?.year);

          setOrdersData((prev) => ({
            ...prev,
            series: [
              {
                name: "Deposit",
                data: response.data.ordersData.deposit,
              },
              {
                name: "Withdrawal",
                data: response.data.ordersData.withdraw,
              },
            ],
          }));

          setTransactionData((prev) => ({
            ...prev,
            series: [
              {
                name: "Success",
                data: response.data.transactionData.success,
              },
              {
                name: "Failed",
                data: response.data.transactionData.failed,
              },
              {
                name: "Cancelled",
                data: response.data.transactionData.cancelled,
              },
              {
                name: "Pending",
                data: response.data.transactionData.pending,
              },
            ],
          }));

          setIsLoading(false);
        } else {
          // setData([]);
          openNotification("topRight", "error", "Error", "Error fetching data");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatCurrency = (value) => {
    const number = Number(value);

    if (!Number.isFinite(number)) {
      return "Invalid number";
    }

    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="page-wrapper default-version">
      <AdminSidebar active={"users-analytics"} />
      <AdminHeader />

      {isLoading && <Loader />}
      <div className="body-wrapper bg-gray-100 px-3">
        <div className="bodywrapper__inner">
          {/* Filters Section */}
          <div className="container px-0 d-flex w-100 flex-column gy-3 g-lg-0 justify-content-between align-items-center mb-4">
            <div className="w-100 mb-3 mb-lg-0 ">
              <h4 className="fw-bold">Order Analytics for {titleYear} </h4>
            </div>

            <div className="w-100 overflow-auto overflow-lg-visible py-3 ">
              <div className="d-flex flex-nowrap gap-3">
                <div className="d-flex flex-row flex-nowrap gap-1 flex-shrink-0">
                  <p className="text-black mt-1">From:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="startDate"
                    size={"small"}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="d-flex flex-row gap-1  flex-shrink-0">
                  <p className="text-black mt-1">To:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="endDate"
                    disabled={!startDate}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={today}
                  />
                </div>

                <Select
                  value={selectedYear}
                  style={{ width: 120 }}
                  placeholder="Select a year"
                  onChange={(value) => {
                    setSelectedYear(value);
                  }}
                >
                  <Option value="">Select a year</Option>
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                </Select>
                <Select
                  defaultValue={action}
                  style={{ width: 120, color: "black" }}
                  placeholder="Transaction type"
                  onChange={(value) => {
                    setAction(value);
                  }}
                  className="text-black"
                >
                  <Option selected>Transaction type</Option>
                  <Option value="deposit">Deposit</Option>
                  <Option value="withdraw">Withdrawal</Option>
                </Select>

                <button
                  className="btn btn-primary p-3 flex-shrink-0"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>

          <hr />

          {/* Cards */}
          <div className="row gy-4 mt-3 mt-lg-0">
            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                                    <i className="la las la-users f-size--56 f-size--56 text-black" />
                                  </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Total Deposits
                      </span>
                      <h3 className="text-black">
                        {formatCurrency(data?.totalDeposits || 0) || 0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2 text-black">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                                    <i className="la las la-user-check f-size--56 f-size--56 " />
                                  </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Total Withdrawals
                      </span>
                      <h3 className="text-black">
                        {formatCurrency(data?.totalWithdrawals || 0) || 0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                                    <i className="la las la-user-times f-size--56 f-size--56 text-black " />
                                  </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Avg. Deposit (M'thly)
                      </span>
                      <h3 className="text-black ">
                        {formatCurrency(data?.avgDailyDeposit || 0) || 0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                                    <i className="las la-user-clock f-size--56 f-size--56 text-black" />
                                  </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Avg. Withdrawal (M'thly)
                      </span>
                      <h3 className="text-black">
                        {formatCurrency(data?.avgDailyWithdrawal || 0) || 0.0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          {/* Line Chart */}
          <div className=" py-4">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3">
                    Deposits & Withdrawals Overview - {titleYear}
                  </h5>
                  <Chart
                    options={ordersData.options}
                    series={ordersData.series}
                    type="line"
                    height={300}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3">
                    Transaction Trend Line - {titleYear}
                  </h5>
                  <Chart
                    options={transactionData.options}
                    series={transactionData.series}
                    type="line"
                    height={300}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderAnalytics;
