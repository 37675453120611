
import React, { useState } from "react";
import { Modal, Button } from "antd";
import openNotification from "../../components/OpenNotification";
import axios from "axios";

const ProfitModal = ({ transaction, setIsLoading }) => {
  const [open, setOpen] = useState(false);
  const [ revenue, setRevenue] = useState(0);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    // setIsLoading(true);
    const token = window.sessionStorage.getItem("token");

    const headers = {
          Authorization:
            `Bearer ${token}`
        }

    const body ={
      revenue
    }


    axios
    .patch(`${process.env.REACT_APP_API_URL}/transactions/profit/${transaction._id}`, body, { headers: headers})
      .then((response) => {
        if (response.data.success) {
          // setMessage('Login Successfully')
          openNotification(
            "topRight",
            "success",
            "Success",
            "Transaction Profit Added Successfully"
          );
        
          // setIsLoading(false)
          setOpen(false)
          // setTimeout(() => {
          //   window.location.href = `/admin/transactions`;
          // }, 2000);
        }
      })
      .catch((error) => {
        openNotification(
          "topRight",
          "error",
          "Error",
          error.response.data.message
        );
        
        console.log("error :>> ", error.response.data.message);
      });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // const modalStyle = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   border: '1px solid #000',
  // };


  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px', // Adjust as needed
  };


  return (
    <>
      <span
      onClick={showModal}
      className=""
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        color: "blue !important"
      }}
      >
        <p className="text-blue">Add Profit</p>

      </span>

      <Modal
        open={open}
        // mask={open}
        title={<div style={titleStyle}>Update Profit Earned<hr/></div>}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} style={{ float: "left"}}>
            Cancel
          </Button>,
          <Button onClick={handleOk}>
            OK
          </Button>,
        ]}
        // style={modalStyle}
      >
        <div>
          
        <div className="col-12">
                                <div className="form-group">
                                  <label>Profit Made</label>
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      GHS
                                    </span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="revenue"
                                      placeholder="Enter profit earned..."
                                      onChange={(e) => setRevenue(e.target.value)}
                                    />
                                    
                                  </div>
                                </div>
                              </div>
          
          <hr/></div>
      </Modal>
    </>
  );
};

export default ProfitModal;
