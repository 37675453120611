import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Admin.css";
import AdminSidebar from "../components/AdminSidebar";
import AdminHeader from "../components/AdminHeader";
import openNotification from "../../components/OpenNotification";
import axios from "axios";
import Loader from "../../components/Loader";
import dayjs from "dayjs";

import { DatePicker, Select } from "antd";

import Chart from "react-apexcharts";

const { Option } = Select;

const UserAnalytics = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const currentYear = new Date().getFullYear();
  const today = dayjs();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const minStartDate = useState(dayjs(`01-01-${selectedYear}`));
  const [titleYear, setTitleYear] = useState(currentYear);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [userGrowthData, setUserGrowthData] = useState({
    series: [
      {
        name: "Active Users",
        data: Array.from({ length: 12 }, () => 0),
      },
      {
        name: "New Signups",
        data: Array.from({ length: 12 }, () => 0),
      },
    ],
    options: {
      chart: { id: "user-growth" },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      stroke: { curve: "smooth" },
      markers: { size: 5 },
      dataLabels: { enabled: false },
      legend: { position: "top" },
      // title: { text: `User Growth - ${selectedYear}`, align: "center" },
    },
  });

  // const [userDemographicsData, setUserDemographicsData] = useState({
  //   series: [
  //     {
  //       name: "Users",
  //       data: [],
  //     },
  //   ],
  //   options: {
  //     chart: { id: "user-demographics" },
  //     xaxis: {
  //       categories: [],
  //     },
  //     dataLabels: { enabled: true },

  //   },
  // });

  const [userDemographicsData, setUserDemographicsData] = useState({
    series: [], 
    options: {
      chart: { id: "user-demographics", type: "pie" },
      legend: { position: "bottom", color: "#fff" },
      labels: [],
      dataLabels: { enabled: true },
    },
  });

  useEffect(() => {
    document.title = "User Analytics | BarterFunds";
    const token = window.sessionStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }
    
    setIsLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/analytics/users`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);

          setUserGrowthData((prev) => ({
            ...prev,
            series: [
              {
                name: "Active Users",
                data: response.data.userGrowth.activeUsers,
              },
              {
                name: "New Signups",
                data: response.data.userGrowth.newSignups,
              },
            ],
          }));

          const demographics = response.data.userDemographics;

          setUserDemographicsData((prev) => ({
            ...prev,
            series: Object.values(demographics), 
            options: {
              ...prev.options,
              labels: Object.keys(demographics), 
            }
          }));


          // setUserDemographicsData({
          //   series: Object.values(demographics), 
          //   options: {
          //     ...userDemographicsData.options,
          //     labels: Object.keys(demographics), 
          //   },
          // });


          setIsLoading(false);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  const handleFilter = () => {
    const token = window.sessionStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (startDate && !endDate) {
      openNotification(
        "topRight",
        "error",
        "End Date",
        "Please select an end date."
      );
      // alert("Please select an end date.");
      return;
    }

    setIsLoading(true);

    // Build the URL dynamically
    const baseUrl = `${process.env.REACT_APP_API_URL}/analytics/users`;
    const params = new URLSearchParams();

    params.append("year", selectedYear);

    if (startDate && endDate) {
      params.append("startDate", startDate);
      params.append("endDate", endDate);
    }

    const finalUrl = `${baseUrl}?${params.toString()}`;
    // console.log("Request URL:", finalUrl);

    axios
      .get(finalUrl, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data);
          setData(response.data);
          setTitleYear(response?.data?.year);

          setUserGrowthData((prev) => ({
            ...prev,
            series: [
              {
                name: "Active Users",
                data: response.data.userGrowth.activeUsers,
              },
              {
                name: "New Signups",
                data: response.data.userGrowth.newSignups,
              },
            ],
          }));

          const demographics = response.data.userDemographics;

          setUserDemographicsData((prev) => ({
            ...prev,
            series: Object.values(demographics), 
            options: {
              ...userDemographicsData.options,
              labels: Object.keys(demographics), 
            }
          }));

          setIsLoading(false);
        } else {
          // setData([]);
          openNotification("topRight", "error", "Error", "Error fetching data");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="page-wrapper default-version">
      <AdminSidebar active={"users-analytics"} />
      <AdminHeader />

      {isLoading && <Loader />}
      <div className="body-wrapper bg-gray-100 px-3">
        <div className="bodywrapper__inner">
          {/* Filters Section */}

          <div className="container px-0 d-flex w-100 flex-column gy-3 g-lg-0 justify-content-between align-items-center mb-4">
            <div className="w-100 mb-3 mb-lg-0 ">
              <h4 className="fw-bold">User Analytics for {titleYear}</h4>
            </div>

            <div className="w-100 overflow-auto overflow-lg-visible py-3 ">
              <div className="d-flex flex-nowrap gap-3">
                <div className="d-flex flex-row flex-nowrap gap-1 flex-shrink-0">
                  <p className="text-black mt-1">From:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="startDate"
                    onChange={(date) => setStartDate(date)}
                    value={startDate}
                  />
                </div>

                <div className="d-flex flex-row gap-1  flex-shrink-0">
                  <p className="text-black mt-1">To:</p>
                  <DatePicker
                    className="p-3 border border-secondary rounded"
                    name="endDate"
                    disabled={!startDate}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                    maxDate={today}
                  />
                </div>

                <Select
                  // defaultValue={selectedYear}
                  style={{ width: 120 }}
                  placeholder="Select a year"
                  onChange={(value) => {
                    setSelectedYear(value);
                  }}
                >
                  <Option value="" selected>
                    Select a year
                  </Option>
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                </Select>

                <button
                  className="btn btn-primary p-3 flex-shrink-0"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
          <hr />

          {/* Cards */}
          <div className="row gy-4 mt-3 mt-lg-0">
            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-users f-size--56 f-size--56 text-black" />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Active Users
                      </span>
                      <h3 className="text-black">{data?.activeUsers || 0}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2 text-black">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-user-check f-size--56 f-size--56 " />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        New Signups
                      </span>
                      <h3 className="text-black">{data?.newUsers || 0}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="la las la-user-times f-size--56 f-size--56 text-black " />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Verified Users
                      </span>
                      <h3 className="text-black ">
                        {data?.verifiedUsers || 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-12 col-lg-3">
              <div className="card bg-white overflow-hidden box--shadow2">
                <div className="card-body">
                  <div className="row align-items-center">
                    {/* <div className="col-2">
                        <i className="las la-user-clock f-size--56 f-size--56 text-black" />
                      </div> */}
                    <div className="col-12 text-left">
                      <span className="text-black text--small">
                        Unverified Users
                      </span>
                      <h3 className="text-black">
                        {data?.unverifiedUsers || 0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          {/* Line Chart */}
          <div className=" py-4">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3 bold">
                    User Growth Trend - {titleYear}{" "}
                  </h5>
                  <Chart
                    options={userGrowthData.options}
                    series={userGrowthData.series}
                    type="line"
                    height={300}
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div className="card p-4 shadow-sm h-100">
                  <h5 className="text-center mb-3">
                    User Demographics - {titleYear}{" "}
                  </h5>
                  <Chart
                    options={userDemographicsData.options}
                    series={userDemographicsData.series}
                    type="pie"
                    height={800}
                  />
                </div>
              </div>
            </div>
          </div>

         
         
        </div>
      </div>
    </div>
  );
};

export default UserAnalytics;
